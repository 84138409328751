import { Match, Switch, createSignal, lazy } from "solid-js";
import { render } from "solid-js/web";
import { CONFIG } from "../config";
import { Player, VersionTag } from "../types";
import { PlayerDataProvider } from "./components/PlayerDataContext";
import { generateDebugDate } from "./gameUtil";
import { App } from "./pages/MainPage";

const Results = lazy(() => import("./pages/ResultsPage"));

// @ts-ignore: defined with esbuild
LIVE_RELOAD && import("./liveReload");

export const LOCAL_STORAGE_KEY = "gameData";
export const [view, setView] = createSignal<"main" | "results">("main");
export const [syncState, setSyncState] = createSignal<"loading" | "open" | "unavailable" | "unauthorized">("loading");

const params = new URLSearchParams(location.search);
const debugParam = params.get("debugfill");
/** 
 * when this is true a dummy save state is generated to test the results page 
 * and the real save state in local storage isn't used
 */
export const DEBUG_DATA = debugParam && !isNaN(parseInt(debugParam));

let patchRequestCounter = 0;

saveToken();

let initalPlayerData = localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)!) : [];

if (DEBUG_DATA) {
    // overwrite the real data with dummy data to test the results page
    initalPlayerData = generateDebugDate(parseInt(debugParam));

    console.warn("Debug mode enabled. Using dummy data instead of save state from local storage");
}

if (params.has("debug-api-domain")) {
    CONFIG.apiDomain = params.get("debug-api-domain");
    console.warn(`overwritten API domain: ${CONFIG.apiDomain}`);
}

render(() =>
    <PlayerDataProvider initialPlayers={initalPlayerData}>
        <Switch>
            <Match when={view() === "main"}>
                <App />
            </Match>
            <Match when={view() === "results"}>
                <Results onExit={() => setView("main")} />
            </Match>
        </Switch>
    </PlayerDataProvider>
    , document.getElementById("app") as Element);

export async function sendChanges(playerData: Player[]) {
    if (syncState() === "unauthorized")
        return;

    const token = localStorage.getItem("token");
    let result: Response;

    try {
        result = await fetch(`${CONFIG.apiDomain}/gamedata`, {
            method: "PATCH",
            body: JSON.stringify({
                data: playerData,
                v: getVersionTag()
            }),
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        });
    } catch (error) {
        setSyncState("unavailable");
        return;
    }

    switch (result.status) {
        case 200:
            setSyncState("open");
            break;
        case 401:
            setSyncState("unauthorized");
            break;
        case 400:
            setSyncState(playerData.length ? "unavailable" : "open");
            break;
        default:
            setSyncState("unavailable");
            break;
    }
}

function getVersionTag(): VersionTag {
    return `${Date.now()}-${patchRequestCounter++}`;
}

export async function gotoResults() {
    const token = localStorage.getItem("token");

    await fetch(`${CONFIG.apiDomain}/show_results`, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: token
        },
        body: JSON.stringify({
            "__debug": DEBUG_DATA ? DEBUG_DATA : undefined
        })
    });
}

function saveToken() {
    const token = new URLSearchParams(location.search).get("token");

    if (token) {
        localStorage.setItem("token", token);
        location.href = location.pathname;
    }
}
import { Show, createEffect } from "solid-js";
import { produce } from "solid-js/store";
import { For } from "solid-js/web";
import { DEBUG_DATA, LOCAL_STORAGE_KEY, sendChanges } from "..";
import { CONFIG } from "../../config";
import type { Player } from "../../types";
import { createPlayer } from "../Player";
import { InfoTable } from "../components/InfoTable";
import { usePlayerData } from "../components/PlayerDataContext";
import { PlayerTable } from "../components/PlayerTable";
import { ResultsButton } from "../components/ResultsButton";
import { SyncStateDisplay } from "../components/SyncStateDisplay";
import { BTIcon } from "../components/bootstrap/BTIcon";
import { checkTrickAmount } from "../gameUtil";

export function App() {
    const [players, changePlayerData] = usePlayerData();

    createEffect(() => {
        if (!DEBUG_DATA)
            // don't overwrite acutal save state if in debug mode
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(players));

        sendChanges(players);

        for (let i = 0; i < 12; i++)
            // check all games once after loading
            checkTrickAmount(i, players);
    });

    return (
        <>
            <div class="mt-2">
                <button
                    class="btn btn-success"
                    onclick={() =>
                        changePlayerData(players => [...players, createPlayer(`Spieler ${players.length + 1}`)])
                    }
                    disabled={players.length >= CONFIG.maxPlayers}
                >
                    <BTIcon icon="plus-circle" />
                    {players.length >= CONFIG.maxPlayers ? "Max. Anzahl erreicht" : "Spieler hinzufügen"}
                </button>
                <button
                    class="btn btn-danger ms-2"
                    onclick={() => {
                        if (confirm("Wirklich alle Spieler zurücksetzen?"))
                            changePlayerData(produce(players => {
                                // @ts-ignore
                                players.forEach((p: Player) => p.gameData.forEach(g => g.numCalled = g.numMade = null));
                            }));
                    }}
                >
                    <BTIcon icon="trash-fill" />
                    Reset
                </button>
                <ResultsButton />
                <SyncStateDisplay />
            </div>

            <div style={{ width: "max-content" }}>
                <For each={players}>{(p, idx) =>
                    <PlayerTable isFirst={idx() === 0} player={p} />
                }</For>
                <Show when={players.length}>
                    {/* only render the mismatch display after a player has been added
                    because it needs the height of a player table to align itself on mount.
                    When the component is rendered before any players are added, there's no table 
                    and it gets the wrong height*/}
                    <InfoTable />
                </Show>
            </div>
            <ResultsButton />
        </>
    );
}
